import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { WebViewAnchorButton } from 'shared/routing/WebViewLinks';
import { useCarrierPerformanceIndicators } from '../data/CarrierPerformanceAPI';
import { PerformanceDataVisualization } from './PerformanceDataVisualization';
import { PerformanceDrawerContent } from './PerformanceDrawer';
import {
  calculatePhotosOnSiteMeanRate,
  calculatePhotosOnSiteRates,
  photosOnSiteThreshold,
  totalDeliveriesPlural,
} from './PerformanceHelpers';

function PhotosTakenOnSiteTopPerformer() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">You are a top performer!</Typography>
      <Typography color="textSecondary">
        Your drivers consistently take photos on-site at both pick-up and
        delivery for all vehicles in every load helping you resolve claims and
        build trust with shippers.
      </Typography>
    </Stack>
  );
}

function PhotosTakenOnSiteGoodMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You&apos;re on the right track, but there&apos;s still room to improve.
      </Typography>
      <Typography color="textSecondary">
        Require drivers to take photos on-site at both pick-up and delivery for
        all vehicles in every load to help resolve claims and build trust with
        shippers.
      </Typography>
    </Stack>
  );
}

function PhotosTakenOnSiteCanImproveMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You have room for improvement on this metric.
      </Typography>
      <Typography color="textSecondary">
        Require drivers to take photos on-site at both pick-up and delivery for
        all vehicles in every load to help resolve claims and build trust with
        shippers.
      </Typography>
    </Stack>
  );
}

export default function PhotosTakenOnSiteDrawerContent() {
  const { showLauncherMessanger } = useCustomerSupport();
  const { data } = useCarrierPerformanceIndicators();

  const meanRate = useMemo(
    () => (data ? calculatePhotosOnSiteMeanRate(data) : null),
    [data],
  );

  const threshold = useMemo(
    () => (meanRate != null ? photosOnSiteThreshold(meanRate) : null),
    [meanRate],
  );

  const additionalMetrics = useMemo(() => {
    if (!data) return null;

    const rates = calculatePhotosOnSiteRates(data);
    if (!rates) return null;

    return [
      { label: 'Pickup', value: `${rates.pickupRate}%` },
      { label: 'Delivery', value: `${rates.deliveryRate}%` },
    ];
  }, [data]);

  if (
    !data ||
    meanRate === null ||
    threshold === null ||
    additionalMetrics === null
  ) {
    return null;
  }

  return (
    <Stack space="medium">
      <PerformanceDataVisualization
        variant={threshold}
        meanRate={meanRate}
        totalNumber={totalDeliveriesPlural(data.eta_provided_general_count)}
        additionalMetrics={additionalMetrics}
      />
      <PerformanceDrawerContent>
        <Stack space="small">
          {threshold === 'top' ? (
            <PhotosTakenOnSiteTopPerformer />
          ) : threshold === 'good' ? (
            <PhotosTakenOnSiteGoodMetrics />
          ) : (
            <PhotosTakenOnSiteCanImproveMetrics />
          )}
          <Stack space="xsmall">
            <Typography variant="h4">What is counted?</Typography>
            <Typography color="textSecondary">
              The percentage of times photos are taken at Pickup and Delivery
              locations for Advanced Inspections.
            </Typography>
          </Stack>
          <Stack space="small">
            <Typography variant="h4">Can we help?</Typography>
            <WebViewAnchorButton
              variant="text"
              endIcon={<ChevronRight />}
              target="_blank"
              href="https://support.superdispatch.com/hc/articles/35648829915027-What-are-Carrier-Performance-Metrics"
            >
              Read our Article
            </WebViewAnchorButton>
            {threshold === 'can-improve' && (
              <Button
                variant="text"
                endIcon={<ChevronRight />}
                onClick={() => {
                  showLauncherMessanger();
                }}
              >
                Talk to Us
              </Button>
            )}
          </Stack>
        </Stack>
      </PerformanceDrawerContent>
    </Stack>
  );
}
